import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Layout, Section, Typography, Link } from "../components"

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <Section
        variant="image-right"
        image={
          data.image.childImageSharp ? data.image.childImageSharp.fluid : null
        }
      >
        <br />
        <br />
        <br />
        <br />
        <Typography variant="large">404</Typography>
        <Typography>La page demandée n&apos;existe pas.</Typography>
        <Link to="/">Retourner sur la page d&apos;accueil</Link>
        <br />
        <br />
        <br />
        <br />
      </Section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.object,
}
export default NotFoundPage

export const query = graphql`
  query {
    image: file(relativePath: { eq: "office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
